import React from "react"
import styled from 'styled-components'
import {Button, PrincipleBlurb, Collapsible, StorySlider, TestimonialSection} from '@common'
import HeroMenu  from '@menus/HeroMenu'
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import {navigate} from "gatsby"
import backgroundWave from "@images/wave-landing.svg"
import { useTranslation } from 'react-i18next';
import principleIcons from '@images/principles/icons.js'
import brandIcons from '@images/brands/brands.js'

const BrandsWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width: 100%;
  justify-content:center;
  padding:0 5%;
  img{
    padding:0 5%;
    max-width: 30%;
    opacity:0.7;
  }
`

const LandingWrapper = styled.div`
  padding-bottom:50px;
`

const PrinciplesRow = styled.div`
  display:grid;
  justify-items: center;
  grid-template-columns:1fr;
  @media ${props => props.theme.tabletUp}{
    grid-template-columns:1fr 1fr 1fr;
  }
`

const CallToActionSection = styled.section`
  padding: 280px 0px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-image: url(${backgroundWave});
  background-repeat: no-repeat;
  background-size: contain;
  background-position:center;
  @media ${props => props.theme.phoneLand} {
    padding: 150px 0px;
    background-size: cover;
  }
  @media (min-width: 1900px){
    height:1000px;
    margin-top:50px;
  }
  h2{
    max-width:600px;
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const FAQWrapper = styled.div`
  max-width:1000px;
  margin: 0px auto 50px auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  @media ${props => props.theme.tabletBelow}{
    max-width:90%;
  }
`

const LandingLoggedOut = (props) => {
  const  { stories } = props
  const { t } = useTranslation(['translations','pages'])
  return (
    <LandingWrapper>
      <HeroMenu/> 
      <HomeHeroSection headerTitle={t('pages:root.headerTitle')} headerText={t('pages:root.headerText')} ctaText={t('pages:root.getStarted')}/> 
       {t('pages:landing.sliders', {returnObjects: true}).map(({title, subtitle, storyIds}, index) => 

       <>
        {index === 2 && 
          <>
            <CallToActionSection>
              <HeaderTitle center dangerouslySetInnerHTML={{ __html: t('pages:landing.cta')}}></HeaderTitle>
              <Button  onClick={() => navigate(t('translations:urls.register'))}>{t('pages:root.ctaButton-1')}</Button>
            </CallToActionSection>
            <PrinciplesRow>
              {t('pages:landing.principles', {returnObjects: true}).map(({title, text, iconKey}, index) => 
                <PrincipleBlurb image={principleIcons[iconKey]} text={text} title={title}/>
              )}
            </PrinciplesRow>
            <TestimonialSection/>
          </>
        }
        {index === 4 && 
        <>
          <FAQWrapper>
            <HeaderTitle center >{t('pages:root.faqSectionTitle')}</HeaderTitle>
            {t('pages:landing.faq', {returnObjects: true}).map(({question, answer},index) => 
              <Collapsible key={index} question={question} answer={answer}/>
            )}
          </FAQWrapper>
          <CallToActionSection>
          <HeaderTitle center >{t('pages:landing.brandsTitle')}</HeaderTitle>
          <BrandsWrapper>
            {brandIcons.map((img,index) => (
              <img src={img} alt="brand-logo" />
            ))}
          </BrandsWrapper>
          </CallToActionSection>
        </>
        }
        <StorySlider
          stories={stories} 
          contentIds={storyIds}
          title= {subtitle}
          subtitle={title}
          reverseTitle={true}
        />
      </>
      )}
    </LandingWrapper>
  )

}

export default LandingLoggedOut;