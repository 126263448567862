import React, {useContext, useEffect } from "react";
import {FirebaseContext} from '@components/Firebase';
import LoggedOutLanding from '@loggedOut/LoggedOutLanding';
import {graphql, navigate} from "gatsby";
import Seo from "@components/Seo";
import { useTranslation } from 'react-i18next';
import Header from "@menus/header"
import { useObjectifyStoryArray } from "@hooks/useObjectifyStoryArray"
import LoginTransitionSpinner  from "@common/LoginTransitionSpinner";

const Index = (props) => {
  const {user} = useContext(FirebaseContext);
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const sliderContent = props.pageContext.contentIds
  const transactionID = new Date().valueOf();
  const { t } = useTranslation(['translations','pages'])
  
  useEffect(() => {
    if(typeof window !== "undefined" && props.location.search ==="?p=m" && process.env.NODE_ENV !== "development"){
      window.gtag('event', 'conversion', {
        'send_to': 'AW-955594792/XL7ECNWhieUBEKjw1McD',
        'value': 3.99,
        'currency': 'USD',
        'transaction_id': transactionID
      });
      window.gtag('event', 'purchase', {
        "transaction_id": transactionID,
        "value": 3.99,
        "currency": "USD",
      });
    } 
    if( typeof window !== "undefined" &&  props.location.search ==="?p=a" && process.env.NODE_ENV !== "development"){
      window.gtag('event', 'conversion', {
        'send_to': 'AW-955594792/XL7ECNWhieUBEKjw1McD',
        'value': 35.88,
        'currency': 'USD',
        'transaction_id': transactionID
      });
      window.gtag('event', 'purchase', {
        "transaction_id": transactionID,
        "value": 35.88,
        "currency": "USD",
      });
    }
  },[props.location.search,transactionID]);

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": t('pages:landing.faq', {returnObjects: true}).map(({question, answer}) => {
      const abc = {
        "@type": "Question",
        "name": question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text":  answer
        }
      }
      return abc
    })
  }

  return (
    <>
      <Seo
        title={t('pages:root.seoTitle')}
        description={t('pages:root.seoDescription')}
        structuredData={[faqSchema]}
      />
      <Header location={props.location} />
      {!user ?
        <LoggedOutLanding props={props} sliderContent={sliderContent} stories={storiesObject} />
        :
        <LoginTransitionSpinner />
      }
    </>
  );
}

export const query = graphql`query StoriesQuery($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!, $storyIds: [String!]) {
  allStory(filter: {languages: {in: [$lang]}, id: {in: $storyIds}}) {
    edges {
      node {
        audiodesiresOriginal
        id
        dateCreated
        en @include(if: $isEn) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        es @include(if: $isEs) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        de @include(if: $isDe) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        ...sliderImageLanding
        mainFantasy {
          id
          en @include(if: $isEn) {
            fantasy
          }
          es @include(if: $isEs) {
            fantasy
          }
          de @include(if: $isDe) {
            fantasy
          }
        }
        fantasies {
          id
          en {
            fantasy
            slug
          }
          es @include(if: $isEs) {
            fantasy
            slug
          }
          de @include(if: $isDe) {
            fantasy
            slug
          }
        }
        genre {
          id
          en  @include(if: $isEn) {
            genre
          }
          es  @include(if: $isEs) {
            genre
          }
          de  @include(if: $isDe) {
            genre
          }
          id
        }
        scenario {
          en @include(if: $isEn) {
            scenario
            slug
          }
          es @include(if: $isEs) {
            scenario
            slug
          }
          de @include(if: $isDe) {
            scenario
            slug
          }
        }
      }
    }
  }
}
` 

export default Index





