import React from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from './LoadingSpinner';
const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: white;
  left: 0;
  bottom: 0;
  top:0;
  z-index: 999;
  overflow: hidden;
  display:flex;
  justify-content:center;
  max-width:100%;
`;

const LoginTransitionSpinner = () => {
  const { t } = useTranslation(['components'])

  return (
    <LoadingWrapper>
      <LoadingSpinner message={t('components:loginTransitionSpinner.loggingIn')}/>
    </LoadingWrapper>
  )
}

export default LoginTransitionSpinner