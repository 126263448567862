import cosmopolitan from './cosmopolitan-op.png'
import glamour from './glamour-op.png'
import mashable from './mashable-op.png'
import mindbodygreen from './mindbodygreen.png'
import self from './self-op.png'
import oprah from './OprahDaily_100_K.png'


const brands = [
  cosmopolitan,
  glamour,
  mashable,
  mindbodygreen,
  self,
  oprah
]

export default brands